import { createRouter, createWebHashHistory } from 'vue-router' 

//页面按需引入
const Layout=()=>import('@/views/Layout.vue')
const Home=()=>import('@/views/home/index.vue')
const Note=()=>import('@/views/home/note.vue')
const Login = ()=>import('@/views/login/index.vue') 


const routes = [
  //一级路由
  {
    path:'/',
    component:Layout,
    children:[
      { path:'/',component:Home },
      { path: '/note', component: Note,name:"note" },
    ]
  },
  { path:'/login',component:Login }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  //每次切换时都滚动到页面顶部
  scrollBehavior(){
    return {left:0,top:0}
  }
})

export default router
