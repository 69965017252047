 //用户模块
 export default {
    namespaced:true,
    state () {
        return {
            profile:{
                arr:[]
            }
        }
    },
    mutations:{
        //修改notes数据
        setNote(state,payload){
            state.profile=payload
        }
    }
}