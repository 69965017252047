import { createStore } from 'vuex'
import creatPersistedState from 'vuex-persistedstate'

import user from './modules/user'
import notes from './modules/notes'
//import lang from './modules/lang'

export default createStore({
  modules: {
    user,notes
  },
  plugins:[
    creatPersistedState({
      //本地存储名字
      key:"cn1-store",
      //指定要存储的模块
      paths:['user','notes']
    })
  ]
})