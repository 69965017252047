import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//import { createI18n } from 'vue-i18n';
//import en from './language/en';
//import zh from './language/zh';
import vue3GoogleLogin from 'vue3-google-login'

import { Lazyload } from 'vant'
//import { createHead } from '@unhead/vue'

//重置样式的库
//import 'normalize.css'
//自己写的重置样式
//import '@/assets/styles/common.less'
//import '@/assets/styles/mycommon.less'

// Toast
import 'vant/es/toast/style'

// 组合语言包对象
// const messages = {
//     en,
//     zh
// }

//语言不是简中，就用英语
// let lang = navigator.language
// if(lang!="zh-CN")lang='en'

// 创建实例对象
// const i18n = createI18n({
//     legacy: false,  // 设置为 false，启用 composition API 模式
//     messages,
//     locale: lang
// })

//const head = createHead()

//createApp(App).use(store).use(router).use(Lazyload).use(i18n).use(head).mount('#app') 
createApp(App).use(store).use(router).use(Lazyload).use(vue3GoogleLogin, {
    clientId: '1050858554526-64j3ob5jbuiur29s0pdronn8e042lvgb'
}).mount('#app') 
